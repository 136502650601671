import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "../ApplyPage.module.scss";
import Loader from "../../../components/Loader/Loader";
import Input from "../../../components/Input/Input";
import Swal from "sweetalert2";
import axios from "axios";
import { ArrowLeft } from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ogImage from "../../../assets/images/ogDefault.jpg";

const FullStackForm = () => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [formValue, setFormValue] = useState({
    position: "full stack developer",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    fileName: "",
    file: "",
  });
  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeFile = (event) => {
    setFormValue({
      ...formValue,
      fileName: event.target.files[0].name,
      file: event.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    const contactFormData = new FormData();
    contactFormData.append("position", formValue.position);
    contactFormData.append("first_name", formValue.firstName);
    contactFormData.append("last_name", formValue.lastName);
    contactFormData.append("email", formValue.email);
    contactFormData.append("message", formValue.message);
    contactFormData.append("fileName", formValue.fileName);
    contactFormData.append("file", formValue.file);
    contactFormData.append("concent", "true");

    try {
      const response = await axios({
        method: "post",
        url: "https://mailer-w6t7.onrender.com/careers/",
        data: contactFormData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          background: "#35467d",
          title: "Your application has been sent successfully",
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          backdrop: false,
          toast: true,
          customClass: {
            title: "swal2-title",
          },
        });
        setBtnDisabled(false);
        setFormValue({
          position: "",
          firstName: "",
          lastName: "",
          email: "",
          message: "",
          fileName: "",
          file: "",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        background: "red",
        title: "Oopss...something went wrong",
        text: "Your application has not been submitted",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
        toast: true,
        customClass: {
          title: "swal2-title",
          text: "swal2-text",
        },
      });
      setBtnDisabled(false);
      console.log("Error", error);
    }
  };
  return (
    <div className={styles.apply_form_page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | {`Apply for ${formValue.position}`}</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="At Nearshore GM, we recruit Software Engineers. We're looking for talented professionals with a passion for software engineering, 
            who want to share their knowledge and expertise with our client."
          />
          <link
            rel="canonical"
            href={`https://www.nearshoregm.com/apply-full-stack-form`}
          ></link>

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`Nearshore GM | Apply for ${formValue.position}`}
          />
          <meta
            property="og:description"
            content="At Nearshore GM, we recruit Software Engineers. We're looking for talented professionals with a passion for software engineering, 
            who want to share their knowledge and expertise with our client."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content={`https://www.nearshoregm.com/apply-full-stack-form`}
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <Container>
        <Row>
          <Col lg={12}>
            <form className={styles.form_holder} onSubmit={handleSubmit}>
              <div className={styles.back_arrow_holder}>
                <NavLink
                  to="/apply-full-stack"
                  className={styles.back_arrow_link}
                >
                  <ArrowLeft className={styles.icon} />
                  Job description
                </NavLink>
              </div>
              {btnDisabled && <Loader />}
              <b className={styles.intro_text}>
                Fill out the form and we will contact you as soon as possible
              </b>
              <h1 className={styles.job_title}>
                <b>Full stack developer</b>
              </h1>
              <Input
                type="text"
                placeholder="..."
                labelTitle="First Name"
                name="firstName"
                value={formValue.firstName}
                onChange={handleChange}
                required={true}
              />
              <Input
                type="text"
                placeholder="..."
                labelTitle="Last name"
                name="lastName"
                value={formValue.lastName}
                onChange={handleChange}
                required={true}
              />
              <Input
                type="email"
                placeholder="..."
                labelTitle="Email"
                name="email"
                value={formValue.email}
                onChange={handleChange}
                required={true}
              />
              <label className={styles.label}>
                <span className={styles.label_span}>Message</span>
                <textarea
                  className={styles.textarea}
                  placeholder="..."
                  name="message"
                  value={formValue.message}
                  onChange={handleChange}
                ></textarea>
              </label>
              <Input
                type="file"
                labelTitle="Upload CV"
                name="file"
                onChange={handleChangeFile}
                required={true}
              />
              <label className={`${styles.label} ${styles.checkbox}`}>
                <input type="checkbox" required />
                <span className={`${styles.label_span} ${styles.legal}`}>
                  I agree to having my personal data that are contained in the
                  attached document and provided in this application form
                  collected, processed and stored in Nearshore GM database in
                  accordance with the relevant laws and regulations, for the
                  purpose of potential job offers, as well as for statistical
                  purposes.
                  <br />
                  <br />
                  NOTE: You may withdraw this consent at any time by
                  communicating such intention through an email sent to:{" "}
                  <a
                    href="mailto:info@nearshoregm.com"
                    className={styles.links}
                  >
                    info@nearshoregm.com
                  </a>
                </span>
              </label>
              <input
                type="submit"
                className={`ns_button ${styles.button}`}
                value="Submit your application"
                disabled={btnDisabled}
              />
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FullStackForm;
