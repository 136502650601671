import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "../ApplyPage.module.scss";
import { NavLink } from "react-router-dom";

import bckg from "../../../assets/images/Polygon_Luminary.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ogImage from "../../../assets/images/ogDefault.jpg";

const ApplyPage = () => {
  const [formValue, setFormValue] = useState({
    position: "full stack developer",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    fileName: "",
    file: "",
  });
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | {`Apply for ${formValue.position}`}</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT, kotlin, java, nodejs, typescript"
          />
          <meta
            name="description"
            content="At Nearshore GM, we recruit Software Engineers. We're looking for talented professionals with a passion for software engineering, 
            who want to share their knowledge and expertise with our client."
          />
          <link rel="canonical" href={`${origin}/apply-full-stack`}></link>

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`Nearshore GM | Apply for ${formValue.position}`}
          />
          <meta
            property="og:description"
            content="At Nearshore GM, we recruit Software Engineers. We're looking for talented professionals with a passion for software engineering, 
            who want to share their knowledge and expertise with our client."
          />
          <meta property="og:image" content={ogImage} />
          <meta property="og:url" content={`${origin}/apply-full-stack`} />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <img
        src={bckg}
        alt="plygon background"
        loading="lazy"
        className={`${styles.bckg_image} img-fluid`}
      />
      <Container>
        <Row>
          <Col lg={12}>
            <div className={styles.job_description_holder}>
              <p className={styles.text}>
                <p className={styles.intro_text}>
                  <b>Nearshore GM</b> is a unique IT company that nurtures a
                  culture of teamwork, loyalty and life balance. We code with
                  integrity using modern, proven and up to date technologies.
                  Our commitment is directed to delivering clean and efficient
                  solutions and services for our partner Flyeralarm, one of the
                  largest German e-commerce corporations and one of the largest
                  online printers in Europe, dedicated to environmentally
                  friendly delivering products and services. Our team is growing
                  and we are looking for
                </p>
              </p>
              <h1 className={styles.desc_title}>Full Stack Developer</h1>
              <div className={styles.apply_btn_holder}>
                <NavLink
                  to="/apply-full-stack-form"
                  className={styles.apply_btn}
                >
                  Apply for this job
                </NavLink>
              </div>
              <p className={styles.text}>
                You’ll be part of a multicultural team, working from an office
                based in Niš, 9 to 5. You'll be developing features to make them
                more efficient, breaking major tasks into smaller ones, finding
                and fixing bags in code, reviewing it in close details and
                supporting mentorship of junior colleagues.
              </p>

              <b className={styles.subtitle}>Benefits and perks:</b>
              <p className={styles.text}>
                We offer the opportunity to enhance and develop your career with
                the following benefits:
              </p>
              <ul className={styles.desc_list}>
                <li className={styles.items}>
                  Comprehensive onboarding - 3 to 6 months onboarding process
                  designed to ensure you understand and learn our system without
                  pressure
                </li>
                <li className={styles.items}>
                  Private medical insurance - We prioritize your health with
                  good private medical coverage
                </li>
                <li className={styles.items}>
                  Competitive salary - Salary package that reflects your
                  experience and contribution
                </li>
                <li className={styles.items}>
                  International collaboration - Work in multicultural teams
                  while maintaining working hours within the CET time zone
                </li>
                <li className={styles.items}>
                  Team building - Participate in various team buildings and
                  in-house activities that foster a strong team spiri
                </li>
                <li className={styles.items}>
                  Inspirational workspace - Work from the Think Innovative Hub,
                  featuring great amenities, such as parking and gym, and a
                  beautiful environment surrounded by nature
                </li>

                <li className={styles.items}>
                  Continuous growth - Opportunities for personal development,
                  continuous education and mentorship
                </li>
              </ul>

              <b className={styles.subtitle}>Job responsibilities:</b>
              <p className={styles.text}>
                As Full Stack Developer, you handle these tasks
              </p>
              <ul className={styles.desc_list}>
                <li className={styles.items}>
                  Developing and maintaining both web applications using{" "}
                  <b>Kotlin</b>, <b>Java</b>, <b>Node.js</b>, <b>Typescript</b>{" "}
                  for the backend and <b>Vue.js</b> for the frontend
                </li>
                <li className={styles.items}>
                  Integrating and managing <b>MongoDB</b> to ensure robust data
                  handling and storage solutions
                </li>
                <li className={styles.items}>
                  Writing clean and scalable code in an agile development
                  environment
                </li>
                <li className={styles.items}>
                  Designing and developing REST APIs that ensure fast and smooth
                  product development
                </li>
              </ul>
              <b className={styles.subtitle}> Job requirements:</b>
              <p className={styles.text}>
                To be considered for this role, here are the skills we’re
                looking for:
              </p>
              <ul className={styles.desc_list}>
                <li className={styles.items}>
                  At least 3 years of extensive experience with <b>Kotlin</b>,{" "}
                  <b>Java</b>, <b>Node.js</b> and <b>Typescript</b>
                </li>
                <li className={styles.items}>
                  Strong expertise in developing with <b>Vue.js</b>
                </li>
                <li className={styles.items}>
                  Skilled with <b>MongoDB</b> and <b>MySQL</b>
                </li>
                <li className={styles.items}>
                  Familiarity with DevOps practices and tools, including
                  containerization with <b>Docker</b> and CI/CD tools like{" "}
                  <b>Bamboo</b>
                </li>

                <li className={styles.items}>
                  Knowledge of PHP is a plus, particularly for legacy systems or
                  specific components
                </li>
                <li className={styles.items}>
                  Ability to work independently and effectively within a team
                </li>
                <li className={styles.items}>
                  Strong verbal and written communication skills
                </li>
                <li className={styles.items}>
                  Strong analytical and problem solving skills
                </li>
              </ul>

              <p className={styles.desc_text}>
                <i>
                  We’re looking for people who are curious and proactive in
                  their day-to-day problem solving. If you like sharing
                  knowledge within a team, and are happy to continuously expand
                  your skills, we’ll be happy to receive your application!
                </i>
              </p>
              <div className={styles.apply_btn_holder}>
                <NavLink
                  to="/apply-full-stack-form"
                  className={styles.apply_btn}
                >
                  Apply for this job
                </NavLink>
              </div>
            </div>
          </Col>
          {/* <Col lg={1}><div className={styles.divider}></div></Col> */}
          {/* <Col lg={12}>
            <form className={styles.form_holder} onSubmit={handleSubmit}>
              {btnDisabled && <Loader />}
              <b className={styles.intro_text}>
                Fill out the form and we will contact you as soon as possible
              </b>

              <Input
                type="text"
                placeholder="..."
                labelTitle="First Name"
                name="firstName"
                value={formValue.firstName}
                onChange={handleChange}
                required={true}
              />
              <Input
                type="text"
                placeholder="..."
                labelTitle="Last name"
                name="lastName"
                value={formValue.lastName}
                onChange={handleChange}
                required={true}
              />
              <Input
                type="email"
                placeholder="..."
                labelTitle="Email"
                name="email"
                value={formValue.email}
                onChange={handleChange}
                required={true}
              />
              <label className={styles.label}>
                <span className={styles.label_span}>Message</span>
                <textarea
                  className={styles.textarea}
                  placeholder="..."
                  name="message"
                  value={formValue.message}
                  onChange={handleChange}
                ></textarea>
              </label>
              <Input
                type="file"
                labelTitle="Upload CV"
                name="file"
                onChange={handleChangeFile}
                required={true}
              />
              <label className={`${styles.label} ${styles.checkbox}`}>
                <input type="checkbox" required />
                <span className={`${styles.label_span} ${styles.legal}`}>
                  I agree to having my personal data that are contained in the
                  attached document and provided in this application form
                  collected, processed and stored in Nearshore GM database in
                  accordance with the relevant laws and regulations, for the
                  purpose of potential job offers, as well as for statistical
                  purposes.
                  <br />
                  <br />
                  NOTE: You may withdraw this consent at any time by
                  communicating such intention through an email sent to:{" "}
                  <a
                    href="mailto:info@nearshoregm.com"
                    className={styles.links}
                  >
                    info@nearshoregm.com
                  </a>
                </span>
              </label>
              <input
                type="submit"
                className={`ns_button ${styles.button}`}
                value="Send"
                disabled={btnDisabled}
              />
            </form>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default ApplyPage;
