import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "../ApplyPage.module.scss";
import { NavLink } from "react-router-dom";

import bckg from "../../../assets/images/Polygon_Luminary.png";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ogImage from "../../../assets/images/ogDefault.jpg";

const ApplyPage = () => {
  const [formValue, setFormValue] = useState({
    position: "senior PHP developer",
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    fileName: "",
    file: "",
  });
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | {`Apply for ${formValue.position}`}</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="At Nearshore GM, we recruit Software Engineers. We're looking for talented professionals with a passion for software engineering, 
            who want to share their knowledge and expertise with our client."
          />
          <link rel="canonical" href={`${origin}/apply-php-senior`}></link>

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content={`Nearshore GM | Apply for ${formValue.position}`}
          />
          <meta
            property="og:description"
            content="At Nearshore GM, we recruit Software Engineers. We're looking for talented professionals with a passion for software engineering, 
            who want to share their knowledge and expertise with our client."
          />
          <meta property="og:image" content={ogImage} />
          <meta property="og:url" content={`${origin}/apply-php-senior`} />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <img
        src={bckg}
        alt="plygon background"
        loading="lazy"
        className={`${styles.bckg_image} img-fluid`}
      />
      <Container>
        <Row>
          <Col lg={12}>
            <div className={styles.job_description_holder}>
              <p className={styles.text}>
                <p className={styles.intro_text}>
                  <b>Nearshore GM</b> is a unique IT company that nurtures a
                  culture of loyalty, teamwork and life balance. We code with
                  integrity using modern, proven and up to date technologies.
                  Our commitment is directed to delivering clean and efficient
                  solutions and services for our partner Flyeralarm, one of the
                  largest German e-commerce corporations and one of the largest
                  online printers in Europe, dedicated to environmentally
                  friendly delivering products and services. Our team is growing
                  and we are looking for
                </p>
              </p>
              <h1 className={styles.desc_title}>Senior PHP Developer </h1>
              <div className={styles.apply_btn_holder}>
                <NavLink
                  to="/apply-php-senior-form"
                  className={styles.apply_btn}
                >
                  Apply for this job
                </NavLink>
              </div>
              <p className={styles.text}>
                You’ll be part of a multicultural team on developing new
                features, troubleshooting and ensuring smooth functionality,
                working from an office based in Niš, 9 to 5. You&#39;ll be
                defining initial risks in working with large projects,
                independently solving different problems, distilling complex
                ones through the application of design patterns, regularly
                reviewing code for less experienced colleagues and mentoring
                them.
              </p>

              <b className={styles.subtitle}>Benefits and perks:</b>
              <p className={styles.text}>
                We offer the opportunity to enhance and develop your career with
                the following benefits:
              </p>
              <ul className={styles.desc_list}>
                <li className={styles.items}>
                  Onboarding process from 3 to 6 months - we want you to take
                  time to know our system and learn everything without pressure
                </li>
                <li className={styles.items}>Private medical insurance</li>
                <li className={styles.items}>
                  Competitive salary corresponding with relevant experience and
                  skills
                </li>
                <li className={styles.items}>
                  Working in international and multicultural teams with
                  colleagues across the world (but working hours are always in
                  the CET time zone)
                </li>
                <li className={styles.items}>
                  Team building and inhouse team activities
                </li>
                <li className={styles.items}>
                  Working space in Think Innovative Hub with great amenities,
                  always available parking surrounded with nature and greens
                </li>

                <li className={styles.items}>
                  Personal development and growth, continuous education and
                  mentorship
                </li>

                <li className={styles.items}>Financial and legal counseling</li>

                <li className={styles.items}>Mental health counseling</li>
              </ul>

              <b className={styles.subtitle}>Job responsibilities:</b>
              <p className={styles.text}>
                As Senior PHP Developer, you handle these tasks:
              </p>
              <ul className={styles.desc_list}>
                <li className={styles.items}>
                  Developing web applications based on <b>PHP</b>
                </li>
                <li className={styles.items}>
                  Writing clean and scalable code in an agile development
                  environment
                </li>
                <li className={styles.items}>Using and managing databases</li>
                <li className={styles.items}>
                  Applying quality automated testing practices
                </li>
                <li className={styles.items}>
                  Designing and developing REST APIs that ensure fast and smooth
                  product development
                </li>
                <li className={styles.items}>
                  Ensuring compliance with clean software architecture (Domain
                  Driven Design)
                </li>
                <li className={styles.items}>
                  Mentoring middle and junior developers of the team
                </li>
              </ul>
              <b className={styles.subtitle}> Job requirements:</b>
              <p className={styles.text}>
                To be considered for this role, here are the skills we’re
                looking for:
              </p>
              <ul className={styles.desc_list}>
                <li className={styles.items}>
                  Minimum 5 years of experience in <b>PHP</b> (Native) or one of
                  its frameworks, such as <b>Slim, Laravel, or Symfony,</b> with
                  proficiency in <b>PHP versions 7</b> and above
                </li>
                <li className={styles.items}>
                  Comprehensive knowledge of databases and architectures, OOP
                  design patterns and cloud computing
                </li>
                <li className={styles.items}>
                  Proficiency in working with <b>MySQL</b>, <b>Elasticsearch</b>
                  , <b>Redis</b>
                </li>
                <li className={styles.items}>
                  Proficiency in automating software tests (PHP Unit testing)
                </li>

                <li className={styles.items}>
                  Experience with <b>DevOps</b> practices (Continuous
                  Integration, Continuous deployment): Platforms such as{" "}
                  <b>Linux</b>, <b>Git</b>, <b>Docker</b>; Various tools:{" "}
                  <b>Bamboo</b>, <b>Ansible</b>
                </li>
                <li className={styles.items}>
                  Experience in working with <b>Jira</b> and <b>Confluence</b>
                </li>
                <li className={styles.items}>
                  Experience in creating monitoring solutions in combination
                  with <b>InfluxDB</b> and <b>Grafana</b>
                </li>
                <li className={styles.items}>
                  Ability to work independently and in a team, and desire to
                  gain and share knowledge with teammates
                </li>
                <li className={styles.items}>
                  Experience in solving problems and tasks of different
                  complexity
                </li>
                <li className={styles.items}>
                  Thorough view of software development, which is used to
                  provide the best suggestions for completing objectives in
                  various areas
                </li>
              </ul>

              <p className={styles.desc_text}>
                <i>
                  We’re looking for people who are curious, independent, and
                  proactive in their day-to-day problem solving. If you like
                  sharing knowledge within a team, and are happy to continuously
                  expand your skills, we’ll be happy to receive your
                  application! Don&#39;t be discouraged if you feel like you’re
                  missing some of the skills we require. Feel free to reach out
                  to us and we can provide you with guidance and tips!
                </i>
              </p>
              <div className={styles.apply_btn_holder}>
                <NavLink
                  to="/apply-php-senior-form"
                  className={styles.apply_btn}
                >
                  Apply for this job
                </NavLink>
              </div>
            </div>
          </Col>
          {/* <Col lg={1}><div className={styles.divider}></div></Col> */}
          {/* <Col lg={12}>
            <form className={styles.form_holder} onSubmit={handleSubmit}>
              {btnDisabled && <Loader />}
              <b className={styles.intro_text}>
                Fill out the form and we will contact you as soon as possible
              </b>

              <Input
                type="text"
                placeholder="..."
                labelTitle="First Name"
                name="firstName"
                value={formValue.firstName}
                onChange={handleChange}
                required={true}
              />
              <Input
                type="text"
                placeholder="..."
                labelTitle="Last name"
                name="lastName"
                value={formValue.lastName}
                onChange={handleChange}
                required={true}
              />
              <Input
                type="email"
                placeholder="..."
                labelTitle="Email"
                name="email"
                value={formValue.email}
                onChange={handleChange}
                required={true}
              />
              <label className={styles.label}>
                <span className={styles.label_span}>Message</span>
                <textarea
                  className={styles.textarea}
                  placeholder="..."
                  name="message"
                  value={formValue.message}
                  onChange={handleChange}
                ></textarea>
              </label>
              <Input
                type="file"
                labelTitle="Upload CV"
                name="file"
                onChange={handleChangeFile}
                required={true}
              />
              <label className={`${styles.label} ${styles.checkbox}`}>
                <input type="checkbox" required />
                <span className={`${styles.label_span} ${styles.legal}`}>
                  I agree to having my personal data that are contained in the
                  attached document and provided in this application form
                  collected, processed and stored in Nearshore GM database in
                  accordance with the relevant laws and regulations, for the
                  purpose of potential job offers, as well as for statistical
                  purposes.
                  <br />
                  <br />
                  NOTE: You may withdraw this consent at any time by
                  communicating such intention through an email sent to:{" "}
                  <a
                    href="mailto:info@nearshoregm.com"
                    className={styles.links}
                  >
                    info@nearshoregm.com
                  </a>
                </span>
              </label>
              <input
                type="submit"
                className={`ns_button ${styles.button}`}
                value="Send"
                disabled={btnDisabled}
              />
            </form>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default ApplyPage;
