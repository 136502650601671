import React, { useState, useEffect } from "react";
import { CaretDownFill } from "react-bootstrap-icons";
import { Link } from "react-scroll";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./Careers.module.scss";
import Swal from "sweetalert2";
import axios from "axios";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ogImage from "../../assets/images/ogDefault.jpg";

import puzzle_blue from "../../assets/images/puzzle_blue.png";
import puzzle_light_blue from "../../assets/images/puzzle_light_blue.png";
import puzzle_red from "../../assets/images/puzzle_red.png";
import puzzle_gray from "../../assets/images/puzzle_gray.png";

const Careers = () => {
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [animatePuzzle, setAnimatePuzzle] = useState(false);
  const [formValue, setFormValue] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    file: "",
  });

  const handleChange = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeFile = (event) => {
    setFormValue({
      ...formValue,
      [event.target.name]: event.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnDisabled(true);
    const contactFormData = new FormData();
    contactFormData.append("first_name", formValue.firstName);
    contactFormData.append("last_name", formValue.lastName);
    contactFormData.append("email", formValue.email);
    contactFormData.append("message", formValue.message);
    contactFormData.append("file", formValue.file);
    contactFormData.append("concent", "true");

    try {
      const response = await axios({
        method: "post",
        url: "https://api.nearshoregm.com/mail/job",
        data: contactFormData,
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          background: "#35467d",
          title: "Your application has been sent successfully",
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          backdrop: false,
          toast: true,
          customClass: {
            title: "swal2-title",
          },
        });
        setBtnDisabled(false);
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        background: "red",
        title: "Oopss...something went wrong",
        text: "Your application has not been submitted",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 5000,
        backdrop: false,
        toast: true,
        customClass: {
          title: "swal2-title",
          text: "swal2-text",
        },
      });
      setBtnDisabled(false);
      console.log(error);
    }
  };
  setTimeout(() => {
    setAnimatePuzzle(true);
  }, 1000);
  useEffect(() => {
    AOS.init({ duration: 900 });
  }, []);
  return (
    <div className={styles.page}>
      <HelmetProvider>
        <Helmet>
          <title>Nearshore GM | Careers at Nearshore GM</title>
          <meta
            name="keywords"
            content="software engineers, software developer, software company, flyeralarm, Nis, Serbia, hiring, job, IT"
          />
          <meta
            name="description"
            content="NEARSHORE GM is dedicated to delivering clean and efficient solutions and services for 
            the respectful partner, with integrity and security, using modern, proven and up-to-date technologies"
          />
          <link
            rel="canonical"
            href="https://www.nearshoregm.com/careers"
          ></link>

          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Nearshore GM | Careers at Nearshore GM"
          />
          <meta
            property="og:description"
            content="At Nearshore GM, we recruit Software Engineers. We're looking for talented professionals with a passion for software engineering, 
            who want to share their knowledge and expertise with our clients."
          />
          <meta property="og:image" content={ogImage} />
          <meta
            property="og:url"
            content={`https://www.nearshoregm.com/careers`}
          />
          <meta property="og:site_name" content="Nearshore GM" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:image:alt" content="Nearshore GM" />
        </Helmet>
      </HelmetProvider>
      <figure className={styles.hero_image_holder}>
        <div className={styles.puzzle_holder}>
          <div className={styles.hover_holder}>
            <img
              src={puzzle_blue}
              alt="puzzle"
              className={`${styles.puzzle_img} ${styles.blue} ${
                animatePuzzle ? styles.animate : ""
              }`}
            />
            <img
              src={puzzle_light_blue}
              alt="puzzle"
              className={`${styles.puzzle_img} ${styles.light_blue} ${
                animatePuzzle ? styles.animate : ""
              }`}
            />
            <img
              src={puzzle_red}
              alt="puzzle"
              className={`${styles.puzzle_img} ${styles.red} ${
                animatePuzzle ? styles.animate : ""
              }`}
            />
            <img
              src={puzzle_gray}
              alt="puzzle"
              className={`${styles.puzzle_img} ${styles.gray} ${
                animatePuzzle ? styles.animate : ""
              }`}
            />
          </div>
        </div>
        <div className={styles.info}>
          <h1 className={`${styles.title}`}>We Are Hiring</h1>
          <p className={styles.text}>
            As a company with rapid expansion, we are in constant search for
            motivated technology lovers who want to maximize their potential.
            Want to know more about currently open positions? Feel free to
            contact us.
          </p>
          <Link
            to="form"
            spy={true}
            smooth={true}
            className={`ns_button ${styles.links}`}
          >
            Open positions <CaretDownFill className={styles.icon} />
          </Link>
        </div>
      </figure>
      <div className={styles.form_holder} id="form">
        <Container>
          <Row>
            <Col md={12} data-aos="fade-up" data-aos-once="true">
              <h2 className={styles.title}>Join our team!</h2>
            </Col>
          </Row>
          <form action="" className="form" onSubmit={handleSubmit}>
            <Row>
              <Col lg={7}>
                <h2 className={styles.open_pos_title}>
                  <b>Open job positions</b>
                </h2>
                <div className={styles.open_positions}>
                  <NavLink to="/apply-php-senior" className={styles.link}>
                    <div
                      className={styles.box}
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-once="true"
                    >
                      <h3 className={styles.job_title}>Senior PHP Developer</h3>
                      <button className={`ns_button ${styles.button}`}>
                        Apply Now
                      </button>
                    </div>
                  </NavLink>
                  <NavLink to="/apply-full-stack" className={styles.link}>
                    <div
                      className={styles.box}
                      data-aos="fade-up"
                      data-aos-delay="100"
                      data-aos-once="true"
                    >
                      <h3 className={styles.job_title}>Full Stack Developer</h3>
                      <button className={`ns_button ${styles.button}`}>
                        Apply Now
                      </button>
                    </div>
                  </NavLink>
                </div>
              </Col>
              <Col md={1}></Col>
              <Col
                lg={4}
                data-aos="zoom-in"
                data-aos-duration="1500"
                data-aos-once="true"
              >
                <p className={styles.text}>
                  Working with us is not just another job. By carefully planning
                  every aspect of daily activities, we inspire learning and
                  knowledge transfer in a friendly environment.
                </p>
                <p className={styles.text}>
                  {" "}
                  With the use of fun challenges and interesting projects, we
                  help our team members stay on the constant improvement
                  journey. We are focused on <span>
                    modern technologies
                  </span>{" "}
                  used along with the best practices in industry.
                </p>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </div>
  );
};

export default Careers;
